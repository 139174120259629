import { useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby'
import Paginator from 'react-hooks-paginator';
import Slider from "react-slick";
import styled from 'styled-components';
import { COLORS } from "../../style-vars.js";
import NewShape from "../assets/forma.svg";
import VideoPlayer from "../components/videoplayer";

const Events = () => {
  const [myEvent, setmyEvent] = useState();
  const [showModal, handleModal] = useState(false);
  const [stop, handleStop] = useState(false);
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const eventsData = useStaticQuery(graphql`
      query MyQuery {
        eventos:allFile(filter: {sourceInstanceName: {eq: "eventos"}}) {
          edges {
            node {
              childMarkdownRemark {
                frontmatter {
                  location
                  images
                  videos
                  title
                  description
                  date
                }
              }
            }
          }
        }
      }
      `)

  const events = eventsData.eventos.edges.map(element => element.node.childMarkdownRemark);
  useEffect(() => {
    setData(events);
  }, []);

  useEffect(() => {
    setCurrentData(data.slice(offset, offset + pageLimit));
  }, [offset, data]);

  const closeVideo = () => {
    handleStop(true);
  };

  return (
    <div id="events">
      <SectionThree>
        <InnerDiv>
          <GreenShape src={NewShape} />
          <TitleThree>
            <h1>
              <BlueWord>PRESS AND MEDIA</BlueWord> <GreenWord>.</GreenWord>
            </h1>
          </TitleThree>
          <EventsGrid>
            {currentData.map((element, index) =>
              < EventOverlay key={"index" + index} onClick={() => handleModal(true)}>
                <EventSquare key={"index" + index} background={element.frontmatter.images || element.frontmatter.videos == null ? element.frontmatter.images[0] : element.frontmatter.videos && `"https://img.youtube.com/vi/${element.frontmatter.videos[0]}/0.jpg"`} onClick={() => setmyEvent(element.frontmatter)} >
                  <ThumbnailTitle>{element.frontmatter.title}</ThumbnailTitle>
                  <ThumbnailInfo>{element.frontmatter.date} — {element.frontmatter.location}</ThumbnailInfo>
                </EventSquare>
              </EventOverlay>
            )}
          </EventsGrid>
          <Paginator
            totalRecords={events.length}
            pageLimit={pageLimit}
            pageNeighbours={2}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          {myEvent &&
            <StyledModal isOpen={showModal}>
              <EventDiv>
                <StyledTitle>{myEvent.title}</StyledTitle>
                <StyledLocation>{myEvent.date} — {myEvent.location}</StyledLocation>
                <GalleryDiv className="gallery">
                  <Gallery
                    {...settings}
                  >
                    {myEvent.images && myEvent.images.map((image, index) => (
                      <GalleryImage
                        key={index}
                        src={image}
                        alt={"event_img_" + index}
                      ></GalleryImage>
                    ))}
                    {myEvent.videos && myEvent.videos.map((video, index) => (
                      <VideoWrapper key={index}>
                        <VideoPlayer
                          source={video}
                          toStop={stop}
                          onStop={() => handleStop(false)}
                        />
                      </VideoWrapper>
                    ))}
                  </Gallery>
                </GalleryDiv>
                <StyledDescription>{myEvent.description}</StyledDescription>
                <CloseButton onClick={() => {
                  handleModal(false);
                  closeVideo();
                }}>
                  Close </CloseButton>
              </EventDiv>
            </StyledModal>
          }
        </InnerDiv>
      </SectionThree>
    </div >
  )
}
export default Events;
// ------- Overall CSS ------- //
const SectionThree = styled.div`
  padding: 160px 0;
  width: 100vw;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    margin: 80px 0;
    padding: 0;
  }
`;
const TitleThree = styled.div`
  line-height: 64px;
  max-width: 100%;
  margin-bottom: 72px;
  color: ${COLORS.WHITE};
  & > h1 {
    margin: 0;
  }

  @media (max-width: 600px) {
    text-align: center;
    max-width: 100%;
    margin-bottom: 40px;
  }
`;


const EventsGrid = styled.div`
  max-width:1050px;
  display: flex;
  justify-content: space-evenly;
  align-content: space-evenly;
  flex-wrap: wrap;

  @media only screen and (max-width: 500px) {
    img {
      justify-content: center;

    }
  }
`

const EventOverlay = styled.div`
width: 300px;
height: 300px;
margin-bottom: 3rem;
`

const EventSquare = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background:url( ${props => props.background}) rgba(0, 0, 0, 0.3);
  /* background:url("http://img.youtube.com/vi/ExyLgoJeJXI/0.jpg") rgba(0,0,0,0.3); */
  background-position:center;
	background-repeat:no-repeat;
	background-size:cover;
  background-blend-mode: multiply;
  max-height: max-content;
  height: 100%;
  box-shadow: 0px 0px 16px 0px rgba(0,0,0,.3);
  padding: 16px;
`

const ThumbnailTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${COLORS.WHITE};
  margin-bottom: 0px;

`
const ThumbnailInfo = styled.p`
  color: ${COLORS.WHITE};
  margin-bottom: 0;
`
const EventDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
  height: fit-content;
  padding: 48px 60px;
  background-color: ${COLORS.BLUE};

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width:500px) {
    padding: 24px 30px;
  }
`

const StyledTitle = styled.h1`
  color: ${COLORS.DARK_GREY};
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;

  @media only screen and (max-width:500px) {
    font-size: 24px;
  }
`
const StyledLocation = styled.p`
  color: ${COLORS.DARK_GREY};
  font-size: 24px;

  @media only screen and (max-width:500px) {
    font-size: 16px;
  }
`
const StyledDescription = styled.p`
  color: ${COLORS.DARK_GREY};
  font-size: 16px;
  text-align: center;
  max-height: 108px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 24px;
  margin-top: 32px;

  @media only screen and (max-width:500px) {
    max-height: 48px;
    font-size: 14px;
    margin-top: 0;

  }
`

const InnerDiv = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1312px) {
    margin: 0 24px;
  }
`;

const CloseButton = styled.button`
  background-color: ${COLORS.WHITE};
    color: ${COLORS.DARK_GREY};
    /* color: white; */
    border-radius: 16px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 24px;
    cursor: pointer;
`

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

const GalleryDiv = styled.div`
  width: 100%;
  margin: 2rem auto 3rem auto;
  max-height: 460px;
  height: auto;
`;

const Gallery = styled(Slider)`
  width: 100%;
  height: auto;
  .slick-list {
    width: 100%;
    margin: auto;
  }
  .slick-dots li {
    margin: 0 2px;
  }
  .slick-dots li button {
    padding: 5px;
  }
  .slick-dots li button:before {
    font-size: 8px;
  }
  .slick-prev,
  .slick-next {
    width: 28px;
    height: 28px;
  }


  .slick-slide{
    padding: 0 3rem;
    display: inline-block !important;
    vertical-align: middle;
    text-align: center;
    float: none;
    max-height: 500px;
    max-width: 780px;
    height: auto;
    width: auto;
  }

  /* @media only screen and (max-width: 1024px) {
    margin-top: 32px;
  } */

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (max-width: 414px) {
    /* width: 90%; */
    .slick-list {
      width: 80%;
    }
  }
`;

const GalleryImage = styled.img`
  padding: 0 3rem;
  max-height: 460px;
  height: auto;
  width: auto !important;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }

`


const GreenWord = styled.span`
  color: ${COLORS.GREEN};
  font-weight: 900;
`;

const BlueWord = styled.span`
  color: ${COLORS.BLUE};
  font-weight: 900;
`;

const GreenShape = styled.img`
  z-index: -1;
  width: 200px;
  position: absolute;
  left: 0;
  margin-top: 60px;
  opacity: 0.4;

  @media (max-width: 1024px) {
    width: 150px;
    margin-top: 40px;
  }
  @media (max-width: 600px) {
    width: 100px;
    margin-top: 40px;
  }
  @media (max-width: 414px) {
    width: 60px;
    margin-top: 20px;
  }
`;

const VideoWrapper = styled.div`
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%;

  iframe{
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
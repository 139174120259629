import React from "react";
import styled from "styled-components";
import Scheme from "../assets/scheme.png";
import MyMap from "../assets/map.svg";
import { COLORS } from "../../style-vars.js";
class Consortium extends React.Component {
  render() {
    return (
      <div id="consortium">
        <ConsortiumDiv>
          <InnerDiv>
            <h1>
              <BlueWord>WHO WE ARE</BlueWord> <GreenWord>.</GreenWord>
            </h1>
            <ConsortiumDescription>
              Smartlamppost is a joint venture between three of the most
              important players in Europe, in metal structures, Telecoms, Power
              and Smart Cities. Allowing a merge of skills, capabilities and
              market presence that will endow Smartlamppost with the right tools
              to become a reference for.
            </ConsortiumDescription>
            <ConsortiumSection>
              <SchemeSection>
                <ConsortiumScheme src={Scheme} />
              </SchemeSection>
              <ConsortiumMapSection>
                <ConsortiumMap src={MyMap} />
                <ConsortiumCountries>
                  <ConsortiumList>
                    <p>
                      <BlueWord>EUROPE</BlueWord>
                    </p>
                    <p>Belgium</p>
                    <p>France</p>
                    <p>Germany</p>
                    <p>Italy</p>
                    <p>Poland</p>
                    <p>Portugal</p>
                    <p>Spain</p>
                    <p>United Kingdom</p>
                    <p>Ukraine</p>
                  </ConsortiumList>
                  <ConsortiumList>
                    <p>
                      <BlueWord>MEA</BlueWord>
                    </p>
                    <p>Algeria</p>
                    <p>Angola</p>
                    <p>Mozambique</p>
                    <p>Namibia</p>
                    <p>Saudi Arabia</p>
                    <p>Senegal</p>
                    <p>South Africa</p>
                  </ConsortiumList>
                  <ConsortiumList>
                    <p>
                      <BlueWord>AMERICA</BlueWord>
                    </p>
                    <p>Brazil</p>
                    <p>Canada</p>
                  </ConsortiumList>
                </ConsortiumCountries>
              </ConsortiumMapSection>
            </ConsortiumSection>
          </InnerDiv>
        </ConsortiumDiv>
      </div>
    );
  }
}

export default Consortium;

// ------- Overall CSS ------- //

const InnerDiv = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1312px) {
    margin: 0 24px;
  }
`;

const BlueWord = styled.span`
  color: ${COLORS.BLUE};
`;

const GreenWord = styled.span`
  color: ${COLORS.GREEN};
`;

// ------- Section seven - Who are we ------- //

const ConsortiumDiv = styled.div`
  padding: 160px 0;
  width: 100vw;
  height: fit-content;
  text-align: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 600px) {
    padding: 80px 0;
  }
`;

const ConsortiumDescription = styled.p`
  width: 40%;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ConsortiumSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 140px;
  position: relative;
  @media (max-width: 1024px) {
    flex-flow: wrap column;
    margin-top: 80px;
    align-items: center;
  }
`;
const ConsortiumList = styled.div`
  text-align: right;
  width: 30%;
  padding-left: 16px;
  & > p {
    margin: 0;
  }
  @media (max-width: 1024px) {
    text-align: center;
  }
  @media (max-width: 320px) {
    padding-left: 8px;
  }
`;

const ConsortiumMapSection = styled.div`
  position: relative;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
  @media (max-width: 1024px) {
    padding: 0;
    align-items: center;
    margin-top: 80px;
  }
  @media (max-width: 414px) {
    width: 100%;
  }
`;

const ConsortiumCountries = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 60%;
  padding-left: 16px;
  & > p {
    margin: 0;
  }

  @media (max-width: 1024px) {
    position: relative;
    width: 70%;
    margin: 0;
    padding: 0;
    right: auto;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ConsortiumMap = styled.img`
  width: 900px;
  align-self: right;
  opacity: 0.4;

  @media (max-width: 1024px) {
    width: 700px;
    margin-bottom: 40px;
  }
  @media (max-width: 375px) {
  }
`;

const SchemeSection = styled.div`
  width: 30%;
  height: fit-content;
  position: relative;
  @media (max-width: 1024px) {
    flex-flow: wrap column;
    align-content: center;
  }
  @media (max-width: 500px) {
    width: 60%;
  }
`;

const ConsortiumScheme = styled.img`
  width: 300px;
  align-self: center;

  @media (max-width: 1024px) {
    width: 300px;
    margin-bottom: 40px;
  }
  @media (max-width: 414px) {
    width: 200px;
    margin-bottom: 40px;
  }
`;

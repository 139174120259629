import React from "react";
import styled from "styled-components";
import { COLORS } from "../../style-vars.js";

class Partners extends React.Component {
  render() {
    const partners = [
      {
        name: "schneider",
        image: "../assets/logos/partner1.svg",
        href: "https://www.se.com/pt/pt/"
      },
      {
        name: "magnumcap",
        image: "../assets/logos/partner2.svg",
        href: "https://magnumcap.com/"
      },
      {
        name: "huberandsuhner",
        image: "../assets/logos/partner3.svg",
        href: "https://www.hubersuhner.com/en"
      },
      {
        name: "soneres",
        image: "../assets/logos/partner4.svg",
        href: "https://www.soneres.pt/en/"
      },
      {
        name: "accelleran",
        image: "../assets/logos/partner5.svg",
        href: "https://www.accelleran.com/"
      },
      {
        name: "cmguimaraes",
        image: "../assets/logos/partner6.svg",
        href: "https://www.cm-guimaraes.pt/"
      }
    ];

    return (
      <div id="partners">
        <PartnersSection>
          <InnerDiv>
            <PartnersTitle>
              <h1>
                OUR PARTNERS <GreenWord>.</GreenWord>
              </h1>
            </PartnersTitle>
            <PartnersGrid>
              {partners.map((partner, index) => (
                <PartnerLogoDiv key={index}>
                  <a
                    target="_blank"
                    href={partner.href}
                    rel="noopener noreferrer"
                  >
                    <PartnerLogo src={partner.image}></PartnerLogo>
                  </a>
                </PartnerLogoDiv>
              ))}
            </PartnersGrid>
          </InnerDiv>
        </PartnersSection>
      </div>
    );
  }
}
export default Partners;

// ------- Overall CSS ------- //

const InnerDiv = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1312px) {
    margin: 0 24px;
  }
`;

const GreenWord = styled.span`
  color: ${COLORS.GREEN};
  font-weight: 900;
`;

// ------- Section Eigth - Partners ------- //
const PartnersSection = styled.div`
  padding: 160px 0;
  width: 100vw;
  height: fit-content;
  background-color: #f5f5f5;
  @media (max-width: 600px) {
    padding: 80px 0;
  }
`;

const PartnersTitle = styled.div`
  margin: auto;
  text-align: center;
`;

const PartnersGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 56px;
  z-index: 1;
  @media (max-width: 600px) {
    margin-top: 0;
  }
`;

const PartnerLogoDiv = styled.div`
  flex: 1 0 20%;
  max-width: 400px;
  height: 40px;
  margin: 36px;

  @media (max-width: 795px) {
    max-width: 190px;
  }
  @media (max-width: 600px) {
    flex: 1 0 50%;
    max-width: 150px;
    margin: 24px;
  }
`;

const PartnerLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

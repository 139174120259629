import React from "react";
import YouTube from 'react-youtube';


class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoRef: null,
        };
        this._onReady = this._onReady.bind(this);
    }

    _onReady = event => {
        event.target &&
            this.setState({ videoRef: event.target });
    }

    componentDidUpdate() {
        if (this.props.toStop) {
            this.state.videoRef &&
                this.state.videoRef.stopVideo();
            if (this.props.onStop != null) {
                this.props.onStop();
            }
        }
    }

    render() {
        const { source } = this.props;
        return (
            <div>
                <YouTube
                    videoId={source}
                    onReady={this._onReady}
                />
            </div>
        );
    }
}

export default VideoPlayer;


import React, { useState, useEffect } from "react";
import { graphql } from 'gatsby'
import Paginator from 'react-hooks-paginator';
import { useStaticQuery } from "gatsby";
import Slider from "react-slick";
import styled from 'styled-components';

import { COLORS } from "../../style-vars.js";

import VideoPlayer from "../components/videoplayer";


const Portfolio = () => {
  const [myPortfolio, setMyPortfolio] = useState();
  const [showModal, handleModal] = useState(false);
  const [stop, handleStop] = useState(false);
  const pageLimit = 3;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const portfolioData = useStaticQuery(graphql`
      query PortfolioQuery {
        portfolios:allFile(filter: {sourceInstanceName: {eq: "portfolios"}}) {
          edges {
            node {
              childMarkdownRemark {
                frontmatter {
                  location
                  images
                  videos
                  title
                  description
                  date
                }
              }
            }
          }
        }
      }
      `)

  const portfolios = portfolioData.portfolios.edges.map(element => element.node.childMarkdownRemark);
  useEffect(() => {
    setData(portfolios);
  }, []);

  useEffect(() => {
    setCurrentData(data.slice(offset, offset + pageLimit));
  }, [offset, data]);

  const closeVideo = () => {
    handleStop(true);
  };

  return (
    <div id="portfolio">
      <SectionThree>
        <InnerDiv>
          <TitleThree>
            <h1>
              <WhiteWord>PORTFOLIO</WhiteWord><WhiteWord>.</WhiteWord>
            </h1>
          </TitleThree>
          <PortfolioGrid>
            {currentData.map((element, index) =>
              <PortfolioOverlay key={"index" + index} onClick={() => handleModal(true)}>
                <PortfolioSquare key={"index" + index} background={element.frontmatter.images && !element.frontmatter.videos ? element.frontmatter.images[0] : element.frontmatter.videos && `"https://img.youtube.com/vi/${element.frontmatter.videos[0]}/0.jpg"`} onClick={() => setMyPortfolio(element.frontmatter)} >
                  <ThumbnailTitle>{element.frontmatter.title}</ThumbnailTitle>
                  <ThumbnailInfo>{element.frontmatter.date} — {element.frontmatter.location}</ThumbnailInfo>
                </PortfolioSquare>
              </PortfolioOverlay>
            )}
          </PortfolioGrid>
          <Paginator
            totalRecords={portfolios.length}
            pageLimit={pageLimit}
            pageNeighbours={2}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          {myPortfolio &&
            <StyledModal isOpen={showModal}>
              <PortfolioDiv>
                <StyledTitle>{myPortfolio.title}</StyledTitle>
                <StyledLocation>{myPortfolio.date && myPortfolio.date} — {myPortfolio.location && myPortfolio.location}</StyledLocation>
                <GalleryDiv className="gallery">
                  <Gallery
                    {...settings}
                  >
                    {myPortfolio.images && myPortfolio.images.map((image, index) => (
                      <GalleryImage
                        src={image}
                        alt={"event_img_" + index}
                      ></GalleryImage>
                    ))}
                    {myPortfolio.videos && myPortfolio.videos.map((video, index) => (
                      <VideoWrapper key={index}>
                        <VideoPlayer
                          source={video}
                          toStop={stop}
                          onStop={() => handleStop(false)}
                        />
                      </VideoWrapper>
                    ))}
                  </Gallery>
                </GalleryDiv>
                <StyledDescription>{myPortfolio.description && myPortfolio.description}</StyledDescription>
                <CloseButton onClick={() => {
                  handleModal(false);
                  closeVideo();
                }}>Close
                </CloseButton>
              </PortfolioDiv>
            </StyledModal>
          }
        </InnerDiv>
      </SectionThree>
    </div >
  )
}
export default Portfolio;

// ------- Overall CSS ------- //
const SectionThree = styled.div`
background-color: ${COLORS.BLUE};   
  padding: 160px 0;
  width: 100vw;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    padding: 80px 0;
  }
`;

const TitleThree = styled.div`
  line-height: 64px;
  max-width: 100%;
  margin-bottom: 72px;
  color: ${COLORS.WHITE};
  & > h1 {
    margin: 0;
  }

  @media (max-width: 600px) {
    text-align: center;
    max-width: 100%;
    margin-bottom: 40px;
  }
`;


const PortfolioGrid = styled.div`
  max-width:1050px;
  width: 1050px;
  display: flex;
  justify-content: space-evenly;
  align-content: space-evenly;
  flex-wrap: wrap;
  @media only screen and (max-width: 1312px) {
      width: auto;
  }
  @media only screen and (max-width: 500px) {
    img {
      justify-content: center;

    }
  }
`

const PortfolioOverlay = styled.div`
width: 300px;
height: 300px;
margin-bottom: 3rem;
margin-right: 2rem;
@media only screen and (max-width: 500px) {
    margin-right: 0;

  }
`

const PortfolioSquare = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background:url( ${props => props.background}) rgba(0, 0, 0, 0.3);
  background-position:center;
	background-repeat:no-repeat;
	background-size:cover;
  background-blend-mode: multiply;
  max-height: max-content;
  height: 100%;
  box-shadow: 0px 0px 16px 0px rgba(43,113,134,1);
  padding: 16px;
`

const ThumbnailTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${COLORS.WHITE};
  margin-bottom: 0px;

`
const ThumbnailInfo = styled.p`
  color: ${COLORS.WHITE};
  margin-bottom: 0;
`
const PortfolioDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
  height: fit-content;
  padding: 48px 60px;
  background-color: ${COLORS.WHITE};

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width:500px) {
    padding: 24px 30px;
  }
`

const StyledTitle = styled.h1`
  color: ${COLORS.GREY};
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;

  @media only screen and (max-width:500px) {
    font-size: 24px;
  }
`
const StyledLocation = styled.p`
  color: ${COLORS.GREY};
  font-size: 24px;
  @media only screen and (max-width:500px) {
    font-size: 16px;
  }
`
const StyledDescription = styled.p`
  color: ${COLORS.GREY};
  font-size: 16px;
  text-align: center;
  max-height: 108px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 24px;
  margin-top: 32px;

  @media only screen and (max-width:500px) {
    font-size: 14px;
  }
`

const InnerDiv = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1312px) {
    margin: 0 24px;
  }
`;

const CloseButton = styled.button`
  background-color: ${COLORS.BLUE};
  color: ${COLORS.DARK_GREY};
  border-radius: 16px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 24px;
  cursor: pointer;
`

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

const GalleryDiv = styled.div`
  width: 100%;
  margin: 2rem auto 3rem auto;
  max-height: 460px;
  height: auto;
`;

const Gallery = styled(Slider)`
  width: 100%;
  height: auto;
  .slick-list {
    width: 100%;
    margin: auto;
  }
  .slick-dots li {
    margin: 0 2px;
  }
  .slick-dots li button {
    padding: 5px;
  }
  .slick-dots li button:before {
    font-size: 8px;
  }
  .slick-prev,
  .slick-next {
    width: 28px;
    height: 28px;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${COLORS.DARK_GREY};
    font-size: 28px;
  }
  .slick-dots li button:before {
    opacity: 0.1;
  }
  .slick-dots li.slick-active button:before {
    color: ${COLORS.DARK_GREY};
    opacity: 1;
  }
  .slick-slide{
    padding: 0 3rem;
    display: inline-block !important;
    vertical-align: middle;
    text-align: center;
    float: none;
    max-height: 500px;
    max-width: 780px;
    height: auto;
    width: auto;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 32px;
    /* width: 80%; */
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (max-width: 414px) {
    /* width: 90%; */
    .slick-list {
      width: 80%;
    }
  }
`;

const GalleryImage = styled.img`
  padding: 0 3rem;
  max-height: 460px;
  height: auto;
  width: auto !important;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
  `



const WhiteWord = styled.span`
  color: ${COLORS.WHITE};
  font-weight: 900;
`;

const VideoWrapper = styled.div`
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%;

  iframe{
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
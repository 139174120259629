import React from "react";
import window from "global";
import styled from "styled-components";
import Slider from "react-slick";
import SectionBackground from "../assets/backgrounds/features-background.svg";
import { COLORS } from "../../style-vars.js";
import FeatureOne from "../assets/icons/feature-icon-1.svg";
import FeatureTwo from "../assets/icons/feature-icon-2.svg";
import FeatureThree from "../assets/icons/feature-icon-3.svg";
import FeatureFour from "../assets/icons/feature-icon-4.svg";
import FeatureFive from "../assets/icons/feature-icon-5.svg";
import FeatureSix from "../assets/icons/feature-icon-6.svg";
import Mockup1 from "../assets/mockups/mockup1.png";
import Mockup2 from "../assets/mockups/mockup2.png";
import Mockup3 from "../assets/mockups/mockup3.png";
import Mockup4 from "../assets/mockups/mockup4.png";
import Mockup5 from "../assets/mockups/mockup5.png";
import Mockup6 from "../assets/mockups/mockup6.png";
import CloseIcon from "../assets/icons/close.svg";
// import Calendly from "../../public/widget.js";

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      showModal: false,
      myImage: "",
      showCalendly: false,
      fixed: false,
      showCard: true
    };

    this.escFunction = this.escFunction.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.imageClicked = this.imageClicked.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleOpenCalendly = this.handleOpenCalendly.bind(this);
    this.handleCloseCalendly = this.handleCloseCalendly.bind(this);
    this.handleCloseCard = this.handleCloseCard.bind(this);
  }

  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
    document.addEventListener("keydown", this.escFunction, false);
    document.addEventListener("mousedown", this.handleClickOutside);

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > window.innerHeight) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  };

  handleOpenCalendly() {
    this.setState({ showCalendly: true });
  }

  handleCloseCalendly() {
    this.setState({ showCalendly: false });
  }

  handleCloseCard() {
    this.setState({ showCard: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.showModal) {
        this.setState({ showModal: false });
      }
    }
  }

  handleChildClick(e) {
    e.stopPropagation();
    this.setState({ showModal: true });
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.setState({ showModal: false });
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  imageClicked(source) {
    this.setState({ myImage: source });
  }

  render() {
    const myImage = this.state.myImage;
    let fixed = this.state.fixed;

    const showCalendly = this.state.showCalendly;
    const showCard = this.state.showCard;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const settings_image = {
      className: "center",
      dots: false,
      infinite: true,
      arrows: false,
      slidesToShow: 3,
      centerMode: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    const features = [
      {
        index: 0,
        title: "INFRASTRUCTURE MARKETPLACE",
        description: "Monetize investment on infrastructure/sites",
        image: FeatureOne,
        mockup: Mockup1
      },
      {
        index: 1,
        title: "ENERGY CONSUMPTION MONITORING",
        description:
          "Smartlamppost infrastructure allows you to closely monitor your tenants’ energy consumption profiles. Bill accordingly.",
        image: FeatureTwo,
        mockup: Mockup2
      },
      {
        index: 2,
        title: "BILLING CONTROL",
        description: "Get hold of your tenants’ revenue and site costs.",
        image: FeatureThree,
        mockup: Mockup3
      },
      {
        index: 3,
        title: "INFRASTRUCTURE HEALTH MONITORING",
        description:
          "Get notified whenever there is an issue with your infrastructure/site.",
        image: FeatureFour,
        mockup: Mockup4
      },
      {
        index: 4,
        title: "LEASE AND CONTRACTS MANAGEMENT",
        description:
          "Get the most out Smartlamppost’s multi-tenancy infrastructure.",
        image: FeatureFive,
        mockup: Mockup5
      },
      {
        index: 5,
        title: "INTEGRATED THIRD-PARTY ASSET MONITORING",
        description:
          "IoT device? EV Charger? Small Cell? Integrate your Asset Management into Smartlamppost Platform.",
        image: FeatureSix,
        mockup: Mockup6
      }
    ];

    return (
      <div id="features">
        <CalendlyWrapper
          isOpen={showCalendly}
          onClick={this.handleCloseCalendly}
        >
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/smartlamppost-marketplace/demo"
            style={{ minWidth: "320px", minHeight: "720px" }}
          />
          <StyledButton
            buttonColor={COLORS.BLUE}
            onClick={this.handleCloseCalendly}
          >
            Close
          </StyledButton>
        </CalendlyWrapper>
        <FeaturesSection>
          <InnerDiv>
            <FeaturesHeader>
              <FeaturesTitle>
                <h1>MARKETPLACE PLATFORM .</h1>
                <h4>
                  A unified marketplace for digital infrastructure
                  <br /> and multi-purpose sites.
                </h4>
              </FeaturesTitle>
              <FeaturesText>
                <p>
                  Smartlamppost solutions are complemented with a management
                  platform that allows tenants and owners/landlords to perform
                  lease and asset management tasks. <br />
                  It also offers a marketplace where Smartlamppost solutions
                  owners can promote their resources in order to engage with new
                  tenants.
                </p>
              </FeaturesText>
            </FeaturesHeader>

            <CarouselDiv>
              <CarouselSlider
                {...settings}
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
              >
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <Icon src={feature.image} />
                    <SliderTitle>{feature.title}</SliderTitle>
                    <SliderText>{feature.description}</SliderText>
                  </Feature>
                ))}
              </CarouselSlider>
            </CarouselDiv>
          </InnerDiv>
          <MockupDiv>
            <MockupGallery
              {...settings_image}
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
            >
              {features.map((feature, index) => (
                <div key={index} onClick={this.handleOpenModal}>
                  <MockupImage
                    src={feature.mockup}
                    onClick={() => this.imageClicked(feature.mockup)}
                  ></MockupImage>
                </div>
              ))}
            </MockupGallery>
          </MockupDiv>
          <StyledModal isOpen={this.state.showModal} ref={this.setWrapperRef}>
            <ModalImage
              alt=""
              src={myImage}
              onClick={this.handleChildClick}
            ></ModalImage>
            <button onClick={this.handleCloseModal}>Close</button>
          </StyledModal>
          <StyledCard position={fixed} showCard={showCard}>
            <img alt="" src={CloseIcon} onClick={this.handleCloseCard} />
            <p>
              <strong>Want to know more?</strong> <br />
              Schedule a 1-1 conference call with our team
            </p>
            <StyledButton onClick={this.handleOpenCalendly}>
              Schedule a demo
            </StyledButton>
          </StyledCard>
          <StyledMobileButton
            position={fixed}
            onClick={this.handleOpenCalendly}
          >
            Schedule a demo
          </StyledMobileButton>
        </FeaturesSection>
      </div>
    );
  }
}

export default Features;

// ------- Overall CSS ------- //

const InnerDiv = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media only screen and (max-width: 1312px) {
    margin: 0 24px;
  }
`;

// ------- Section five - Features ------- //
const FeaturesSection = styled.div`
  padding: 160px 0;
  width: 100vw;
  height: fit-content;
  position: relative;
  background: url(${SectionBackground});
  /* background-color: #39e6b5; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* color: white !important; */
  @media only screen and (max-width: 600px) {
    padding: 80px 0;
  }
`;

const FeaturesHeader = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const FeaturesTitle = styled.div`
  max-width: 800px;
  text-align: center;
  color: ${COLORS.DARK_GREY};

  & > h1 {
    margin: 0;
    font-weight: 900;
  }

  & > h4 {
    margin-top: 48px;
    margin-bottom: 0;
    font-weight: 900;
    line-height: 34px;
    @media only screen and (max-width: 500px) {
      margin-top: 32px;
      line-height: 22px;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: 600px;
  }
`;

const FeaturesText = styled.div`
  margin-top: 32px;
  max-width: 600px;
  text-align: center;
  color: ${COLORS.DARK_GREY};

  @media only screen and (max-width: 500px) {
    margin-top: 24px;
  }

  & > p {
    @media only screen and (max-width: 500px) {
      margin-bottom: 16px;
    }
  }
`;

const CarouselDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: auto;
  @media only screen and (max-width: 800px) {
    width: 80%;
  }
  @media only screen and (max-width: 414px) {
    width: 85%;
  }
  @media only screen and (max-width: 320px) {
    width: 80%;
  }
`;

const CarouselSlider = styled(Slider)`
  margin-top: 32px;
  margin-bottom: 80px;
  width: 60%;
  text-align: center;
  color: ${COLORS.DARK_GREY};

  .slick-list {
    width: 70%;
    margin: auto;
  }
  .slick-dots li {
    margin: 0 2px;
  }
  .slick-dots li button {
    padding: 5px;
  }
  .slick-dots li button:before {
    font-size: 8px;
  }
  .slick-prev,
  .slick-next {
    width: 28px;
    height: 28px;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${COLORS.DARK_GREY};
    font-size: 28px;
  }
  .slick-dots li button:before {
    opacity: 0.1;
  }
  .slick-dots li.slick-active button:before {
    color: ${COLORS.DARK_GREY};
    opacity: 1;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 32px;
    width: 80%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (max-width: 414px) {
    width: 90%;
    .slick-list {
      width: 80%;
    }
  }
`;

const Icon = styled.img`
  height: 36px;
  fill: ${COLORS.BLACK} !important;
`;

const SliderText = styled.p``;

const SliderTitle = styled.p`
  font-weight: bold;
`;

const Feature = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MockupDiv = styled.div`
  width: 100vw;
  margin: auto;
`;

const MockupGallery = styled(Slider)`
  cursor: pointer;

  .slick-center img {
    transform: scale(1.2);
    transition: all 0.5s ease !important;
  }
  .slick-list {
    padding: 30px 50px !important;
  }

  @media only screen and (max-width: 600px) {
    .slick-center img {
      transform: scale(1.1);
    }
    .slick-list {
      padding: 0 60px !important;
    }
  }
`;

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 65%;
    margin: 0;
  }

  button {
    background-color: ${COLORS.BLUE};
    color: ${COLORS.BLACK};
    /* color: white; */
    border-radius: 16px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 24px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1024px) {
    img {
      width: 85%;
    }
  }

  @media only screen and (max-width: 500px) {
    img {
      width: 95%;
    }
  }
`;

const ModalImage = styled.img`
  pointer-events: none;
`;

const MockupImage = styled.img`
  /* width: 60%;
  height: auto;
  box-shadow: 0 0 30px 0 #96a5a1;
  z-index: 2;
  align-self: center;
  @media (max-width: 1024px) {
    width: 80%;
  } */
`;

const CalendlyWrapper = styled.div`
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(160, 160, 160, 0.4);
  z-index: 200;
  height: 100%;
  width: 100%;

  .calendly-inline-widget {
    overflow: hidden;
    flex-shrink: 0;
    margin-bottom: 1rem;
    width: 1000px;
    align-self: center;
  }

  button {
    align-self: center;
  }
  @media only screen and (max-width: 600px) {
    .calendly-inline-widget {
      height: 500px;
      min-height: 500px !important;
      width: auto;
    }
  }
`;

const StyledButton = styled.button`
  height: fit-content;
  width: fit-content;
  border: none;
  font-size: 14px;
  line-height: 24px;
  border-radius: 16px;
  text-transform: uppercase;
  background-color: ${props => props.buttonColor};
  color: ${COLORS.WHITE};
  padding: 12px 24px;
  font-weight: bold;
  cursor: pointer;
`;

const StyledCard = styled.div`
  display: ${props => (props.showCard ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${COLORS.WHITE};
  padding: 1.5rem;
  margin: 1rem;
  text-align: center;
  position: ${props => (props.position ? "fixed" : "absolute")};
  right: 0;
  bottom: 0;
  z-index: 20;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 #cccccc;
  -webkit-transition: all 0.5s ease;
  -moz-transition: position 10s;
  -ms-transition: position 10s;
  -o-transition: position 10s;
  transition: all 0.5s ease;

  img {
    width: 14px;
    height: 14px;
    align-self: flex-end;
    margin: 0;
    cursor: pointer;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 1rem;
  }

  button {
    font-size: 12px;
    line-height: 20px;
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const StyledMobileButton = styled.button`
  display: none;
  border: none;
  border-radius: 16px;
  text-transform: uppercase;
  padding: 12px 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  @media only screen and (max-width: 600px) {
    display: flex;
    height: fit-content;
    width: fit-content;
    position: ${props => (props.position ? "fixed" : "absolute")};
    bottom: 0;
    margin: 1rem 0;
    align-self: center;
    z-index: 100;
    box-shadow: 0 0 20px 0 #cccccc;
    cursor: pointer;
  }
`;

import React from "react";
import styled from "styled-components";
import Youtube from "../assets/icons/social-5.png";
class Footer extends React.Component {
  render() {
    const socials = [
      // {
      //   name: "facebook",
      //   image: "../assets/icons/social-1.svg",
      //   link: ""
      // },
      // {
      //   name: "instagram",
      //   image: "../assets/icons/social-2.svg"
      // },
      {
        name: "linkedin",
        image: "../assets/icons/social-3.svg",
        link: "https://www.linkedin.com/company/smartlamppost/"
      },
      {
        name: "youtube",
        image: Youtube,
        link: "https://www.youtube.com/channel/UCsbqefGInVYnaqtLsUCcNkg"
      }
      // {
      //   name: "twitter",
      //   image: "../assets/icons/social-4.svg"
      // }
    ];
    return (
      <FooterDiv>
        <InnerDiv>
          <FooterSection>
            <SocialGrid>
              {socials.map((social, index) => (
                <SocialsLogoDiv key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={social.link}
                  >
                    <SocialLogo src={social.image}></SocialLogo>
                  </a>
                </SocialsLogoDiv>
              ))}
            </SocialGrid>
            <Copywrite>
              <BoldText>© Smartlamppost.</BoldText> All rights reserved. 2019
            </Copywrite>
          </FooterSection>
        </InnerDiv>
      </FooterDiv>
    );
  }
}
export default Footer;

const BoldText = styled.span`
  font-weight: bold;
`;

const InnerDiv = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1312px) {
    margin: 0 24px;
  }
`;

// ------- Footer ------- //
const FooterDiv = styled.div`
  background-color: #f5f5f5;
`;

const FooterSection = styled.div`
  padding: 16px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  height: fit-content;
  @media (max-width: 600px) {
    flex-flow: column wrap;
    padding: 32px 0;
  }
`;

const SocialGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: 500px) {
    align-content: center;
  }
`;

const SocialsLogoDiv = styled.div`
  width: 100%;
  max-width: 24px;
  margin: 12px;
`;

const SocialLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: 0;
`;

const Copywrite = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

import React from "react";
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link, animateScroll as scroll } from "react-scroll";
import { ParallaxProvider } from "react-scroll-parallax";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import MyHeader from "../components/header";
import Events from "../components/events";
import Solution from "../components/solution";
import Product from "../components/product";
import Features from "../components/features";
// import News from "../components/news";
import Consortium from "../components/consortium";
import Partners from "../components/partners";
import Newsletter from "../components/newsletter";
import Footer from "../components/footer";
import { COLORS } from "../../style-vars.js";
import window from "global";
import BlueLogo from "../assets/logos/logo_slp_blue.png";
import Portfolio from "../components/portfolio";
// import WhiteLogo from "../assets/logos/logo_slp_white.svg";

if (typeof window === "undefined") {
  global.window = {};
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
      visible: false
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > window.innerHeight - 70) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  };

  handleClick = () => {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  };

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    let fixed = this.state.fixed;
    let visible = this.state.visible;

    const navBar = [
      { name: "solution", content: "SOLUTION" },
      { name: "product", content: "PRODUCT" },
      { name: "features", content: "PLATFORM" },
      // { name: "news", content: "NEWS" },
      { name: "consortium", content: "WHO WE ARE" },
      { name: "portfolio", content: "PORTFOLIO" },
      { name: "events", content: "PRESS AND MEDIA" },
      { name: "partners", content: "PARTNERS" },
      { name: "newsletter", content: "CONTACTS" }
    ];

    return (
      <ParallaxProvider>
        <Helmet>
          <title>Smartlamppost</title>
          {/* <script src={withPrefix('gatsby-node.js')} type="text/javascript" /> */}
        </Helmet>
        <div onScroll={this.handleScroll}>
          <Navbar position={fixed}>
            <NavbarLogo
              position={fixed}
              src={fixed ? BlueLogo : ""}
              onClick={this.scrollToTop}
            />
            <NavbarDiv>
              <NavbarLinks position={fixed}>
                {navBar.map((ele, index) => (
                  <li key={index}>
                    <Link
                      activeClass="active"
                      to={ele.name}
                      spy={true}
                      smooth={true}
                      offset={-64}
                      duration={500}
                    >
                      {ele.content}
                    </Link>
                  </li>
                ))}
              </NavbarLinks>
              <StyledBurger
                visible={visible}
                position={fixed}
                onClick={this.handleClick}
              >
                <div></div>
                <div></div>
                <div></div>
              </StyledBurger>
              <NavbarPanel
                visibility={
                  visible === true ? "translateY(0)" : "translateY(-100%)"
                }
                position={fixed}
              >
                {navBar.map((ele, index) => (
                  <li key={index}>
                    <Link
                      activeClass="active"
                      to={ele.name}
                      spy={true}
                      smooth={true}
                      offset={-64}
                      duration={500}
                    >
                      {ele.content}
                    </Link>
                  </li>
                ))}
              </NavbarPanel>
              <ButtonContainer position={fixed}>
                <a
                  href="https://platform.smartlamppost.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledButton>Go to Marketplace</StyledButton>
                </a>

                {/* <StyledButton onClick={this.handleOpenModal}>
                    Watch our demo
                  </StyledButton> */}
              </ButtonContainer>
            </NavbarDiv>
          </Navbar>
          {/* Header */}
          <MyHeader />
          {/* Section One */}
          <Solution />
          {/* Section Three */}
          <Product />
          {/* Section Five */}
          <Features />
          {/* Section Six */}
          {/* <News  /> */}
          {/* Section Seven */}
          <Consortium />
          {/* Section Eight */}
          <Portfolio />
          {/* Press and Media */}
          <Events />
          {/* Partners */}
          <Partners />
          {/* Section Nine */}
          <Newsletter />
          {/* Footer */}
          <Footer />
        </div>
      </ParallaxProvider>
    );
  }
}

export default IndexPage;

// ------- NAVBAR ------- //

const Navbar = styled.div`
  width: 100vw;
  height: 64px;
  position: fixed;
  top: 0;
  background-color: ${props => (props.position ? "white" : "transparent")};
  z-index: 5;
  box-shadow: ${props =>
    props.position ? "0 2px 20px 0 rgba(224, 224, 224, 0.5)" : "none"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 64px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: position 10s;
  -ms-transition: position 10s;
  -o-transition: position 10s;
  transition: all 0.5s ease;
  @media (max-width: 1024px) {
    padding: 16px 32px;
  }
  @media (max-width: 600px) {
    padding: 16px 16px;
  }
`;

const NavbarLogo = styled.img`
  margin: ${({ position }) => (position ? "0" : "auto")};
  height: ${({ position }) => (position ? "24px" : "32px")};
  cursor: pointer;

  @media (max-width: 600px) {
    height: 16px;
  }
`;

const NavbarDiv = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: 1400px) {
   flex-direction: row-reverse;
  }

`

const NavbarLinks = styled.div`
  display: flex;
  & > li {
    cursor: pointer;
    display: ${({ position }) => (position ? "inherit" : "none")};
    margin: 0;
    list-style-type: none;
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
    margin-left: 40px;
    text-decoration: none;
    color: ${COLORS.GREY};

    & :hover {
      color: #39e6b5;
    }
  }

  & a.active {
    color: #39e6b5;
  }
  @media (max-width: 1400px) {
    display: none;
  }
`;

const StyledBurger = styled.button`
  display: none;

  @media (max-width: 1400px) {
    position: relative;
    display: ${({ position }) => (position ? "flex" : "none")};
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }

    div {
      width: 24px;
      height: 3px;
      background: ${({ position }) => (position ? "black" : "white")};
      border-radius: 18px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${({ visible }) =>
    visible ? "rotate(45deg)" : "rotate(0)"};
      }

      :nth-child(2) {
        opacity: ${({ visible }) => (visible ? "0" : "1")};
        transform: ${({ visible }) =>
    visible ? "translateX(20px)" : "translateX(0)"};
      }

      :nth-child(3) {
        transform: ${({ visible }) =>
    visible ? "rotate(-45deg)" : "rotate(0)"};
      }
    }
  }
  @media (max-width: 600px) {
    width: 16px;
    height: 16px;
    div {
      width: 16px;
      height: 2px;
    }
  }
`;

const NavbarPanel = styled.div`
  display: none;
  overflow: hidden;

  @media (max-width: 1400px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${COLORS.BLUE};
    height: 100vh;
    text-align: center;
    padding: 2rem 4rem;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    width: 40%;
    transform: ${props => props.visibility};

    li {
      cursor: pointer;
      list-style-type: none;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 16px 0;
      color: ${COLORS.GREY};
      text-decoration: none;

      &:hover {
        transition: color 0.2s ease-in-out;
      }
    }

    & a.active {
      color: white;
      font-weight: bold;
    }
  }
  @media (max-width: 600px) {
    padding: 0 8px;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  display: ${({ position }) => (position ? "inherit" : "none")};
`;

const StyledButton = styled.button`
  height: fit-content;
  width: fit-content;
  border: none;
  font-size: 14px;
  line-height: 24px;
  border-radius: 16px;
  text-transform: uppercase;
  background-color: ${COLORS.GREEN};
  color: ${COLORS.GREY};
  padding: 6px 12px;
  margin-left: 40px;
  font-weight: bold;
  cursor: pointer;

  @media (max-width: 1400px) {
   margin-left: 0;
   margin-right: 40px;
  }
`;


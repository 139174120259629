import React from "react";
import window from "global";
import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";
import Post1 from "../assets/postes/poste-1-1.png";
import Post2 from "../assets/postes/poste-2-1.png";
import Pointer from "../assets/pointer.svg";
import PointerGray from "../assets/pointer-gray.svg";
import Tube from "../assets/scheme/tube.png";
import SwitchBoard from "../assets/scheme/switchboard.png";
import Telemetry from "../assets/scheme/telemetry.jpg";
import PowerMeter from "../assets/scheme/power-meter.png";
import GPS from "../assets/scheme/gps.jpg";
import CCTV from "../assets/scheme/cctv.jpg";
import DisguisedTelecom from "../assets/scheme/disguisedtelecom.jpg";
import EVCharger from "../assets/scheme/ev-charger.jpg";
import EVCharger2 from "../assets/scheme/ev-charger2.jpg";
import SectionBackground from "../assets/backgrounds/new-scheme-background.svg";
import SectionBackgroundMobile from "../assets/backgrounds/new-scheme-mobile.svg";
import CloseIcon from "../assets/icons/close-white.svg";
import { COLORS } from "../../style-vars.js";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: null,
      showAll: false,
      showModal: false
    };
    this.toggleCards = this.toggleCards.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  hoverCircle = item => {
    if (this.state.active === item) {
      this.setState({ active: null });
    } else {
      this.setState({ active: item });
    }
  };

  clickCircle = item => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 500) {
        if (this.state.active === item) {
          this.setState({ active: null });
        } else {
          this.setState({ active: item });
        }
      }
    }
  };

  leaveCircle = item => {
    if (this.state.active === item) {
      this.setState({ active: null });
    }
  };

  toggleCards = () => {
    this.setState(prevState => ({
      showAll: !prevState.showAll
    }));
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.showModal) {
        this.setState({ showModal: false });
      }
    }
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.setState({ showModal: false });
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    const formSrc = "https://12de77fb.sibforms.com/serve/MUIEACjeM2dlIQPoUehSh66GTIHGI1oXL0D4ILRr-7Q1ccnSUtxYrOvoh7Zxi3ELSs40koXM_DO80F4SNQz72_o0HfrZQnUzoMLTEXtoLNHo-ildeBC7r0kNtkGdbPMAR7T99m13f_4Kj-39Rjl4nT21nUayRsmcZwi7JZHYkUFcdo8K0MZ_65EPlUsX1JGJwxqj--h8MUSIuhQK";
    const post_one = [
      {
        id: "one",
        newId: "a",
        feature:
          "GPS, WIFI and Telecom Antenna, Smart light with NEMA Controller",
        image: GPS
      },
      {
        id: "one_one",
        newId: "b",
        feature: "Disguised Telecom Antenna",
        image: DisguisedTelecom
      },
      {
        id: "two",
        newId: "c",
        feature: "CCTV and other equipments",
        image: CCTV
      },
      {
        id: "three",
        newId: "d",
        feature: "Telemetry",
        image: Telemetry
      },
      {
        id: "four",
        newId: "e",
        feature: "Switchboard",
        image: SwitchBoard
      },
      {
        id: "five",
        newId: "f",
        feature: "3 PEAD 110mm tube for power, telecom and lease",
        image: Tube
      },
      {
        id: "new",
        newId: "g",
        feature: "EV-Charger | Small Cell",
        image: EVCharger2
      }
    ];

    const post_two = [
      {
        id: "six",
        newId: "b",
        feature: "Disguised Telecom Antenna",
        image: DisguisedTelecom
      },
      {
        id: "six_six",
        newId: "a",
        feature:
          "GPS, WIFI and Telecom Antenna, Smart light with NEMA Controller",
        image: GPS
      },
      {
        id: "seven",
        newId: "c",
        feature: "CCTV and other equipments",
        image: CCTV
      },
      {
        id: "eight",
        newId: "d",
        feature: "Telemetry",
        image: Telemetry
      },
      {
        id: "nine",
        newId: "g",
        feature: "EV-Charger",
        image: EVCharger
      },
      {
        id: "ten",
        newId: "h",
        feature: "Power Meter",
        image: PowerMeter
      },
      {
        id: "eleven",
        newId: "e",
        feature: "Switchboard",
        image: SwitchBoard
      },
      {
        id: "twelve",
        newId: "f",
        feature: "3 PEAD 110mm tube for power, telecom and lease",
        image: Tube
      }
    ];

    const active = this.state.active;
    const toggle = this.state.showAll;

    return (
      <div id="product">
        <ProductSection>
          <InnerDiv>
            <HoverQuote hoverQuote={active !== null ? "hidden" : "visible"}>
              Hover on the circles to <br />
              find out more about our product.
            </HoverQuote>
            <ClickQuote hoverQuote={active !== null ? "hidden" : "visible"}>
              Click the circles to <br />
              find out more about our product.
            </ClickQuote>
            <Scheme>
              <PostOneDiv>
                <PostOne src={Post1} />
                {post_one.map((post, index) => (
                  <PostFeatures key={index} className={index}>
                    <Circle
                      className={"circle_" + post.id}
                      src={active === post.newId ? Pointer : PointerGray}
                      onMouseEnter={() => this.hoverCircle(post.newId)}
                      onMouseLeave={() => this.leaveCircle(post.newId)}
                      onClick={() => this.clickCircle(post.newId)}
                      hoverSize={active === post.newId ? "scale(2,2)" : ""}
                    ></Circle>
                    <Card
                      className={"card_" + post.id}
                      displayMode={active === post.newId ? "flex" : "none"}
                      toggleAll={toggle}
                    >
                      <CardImage>
                        <img alt={"card_image" + index} src={post.image}></img>
                      </CardImage>
                      <CardDescription>
                        <p>{post.feature}</p>
                      </CardDescription>
                    </Card>
                  </PostFeatures>
                ))}
                <SchemeCardOne>
                  {post_one.map((mypost, index) => (
                    <SchemeCardDiv
                      key={index}
                      className={"card" + index}
                      displayMode={active === mypost.newId ? "flex" : "none"}
                    >
                      <CardImage>
                        <img alt={"card_image" + index} src={mypost.image}></img>
                      </CardImage>
                      <CardDescription>
                        <p>{mypost.feature}</p>
                      </CardDescription>
                    </SchemeCardDiv>
                  ))}
                </SchemeCardOne>
              </PostOneDiv>
              <PostTwoDiv>
                {post_two.map((post, index) => (
                  <PostFeatures key={index} className={index}>
                    <Circle
                      className={"circle_" + post.id}
                      src={active === post.newId ? Pointer : PointerGray}
                      onMouseOver={() => this.hoverCircle(post.newId)}
                      onMouseLeave={() => this.leaveCircle(post.newId)}
                      onClick={() => this.clickCircle(post.newId)}
                      hoverSize={active === post.newId ? "scale(2,2)" : ""}
                    ></Circle>
                    <Card
                      className={"card_" + post.id}
                      displayMode={active === post.newId ? "flex" : "none"}
                      toggleAll={toggle}
                    >
                      <CardImage>
                        <img alt={"card_image" + index} src={post.image}></img>
                      </CardImage>
                      <CardDescription>
                        <p>{post.feature}</p>
                      </CardDescription>
                    </Card>
                  </PostFeatures>
                ))}
                <SchemeCardTwo>
                  {post_two.map((mypost, index) => (
                    <SchemeCardDiv
                      key={index}
                      className={"card" + index}
                      displayMode={active === mypost.newId ? "flex" : "none"}
                    >
                      <CardImage>
                        <img alt={"card_image" + index} src={mypost.image}></img>
                      </CardImage>
                      <CardDescription>
                        <p>{mypost.feature}</p>
                      </CardDescription>
                    </SchemeCardDiv>
                  ))}
                </SchemeCardTwo>
                <PostTwo src={Post2} />
              </PostTwoDiv>
            </Scheme>
            <DownloadButton onClick={this.handleOpenModal}>
              Download Catalogue
            </DownloadButton>
            <StyledModal isOpen={this.state.showModal}>
              <img alt="" src={CloseIcon} onClick={this.handleCloseModal} />
              <SendInBlueForm
                src={formSrc}
                frameborder="0"
                scrolling="no"
                allowfullscreen
              ></SendInBlueForm>
            </StyledModal>
            {/* <SchemeButton onClick={this.toggleCards}>
              {" "}
              {toggle ? "HIDE ALL" : "SHOW ALL"}{" "}
            </SchemeButton> */}
          </InnerDiv>
        </ProductSection>
      </div>
    );
  }
}

export default Product;

// ------- Overall CSS ------- //

const InnerDiv = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1024px) {
    margin: 0 24px;
  }
`;

// const BlueWord = styled.span`
//   color: ${COLORS.BLUE};
// `;

// ------- Section four - Scheme ------- //

const ProductSection = styled.div`
  height: max-content;
  position: relative;
  background: url(${SectionBackground});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  padding-top: 32px;
  @media (max-width: 600px) {
    padding-top: 80px;
  }
  @media (max-width: 569px) {
    background: url(${SectionBackgroundMobile});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
  }
`;
const Scheme = styled.div`
  height: fit-content;
  position: relative;
  padding-top: 96px;
  display: flex;
  flex-flow: row;
  @media (max-width: 1024px) {
    padding-top: 24px;
  }
  @media (max-width: 569px) {
    flex-flow: column;
    margin-bottom: 40px;
  }
`;

const bounceAnimation = keyframes`${pulse}`;

const HoverQuote = styled.p`
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 220px;
  opacity: 0.3;
  -webkit-animation: ${bounceAnimation} 2s ease-in 1s infinite;
  animation: ${bounceAnimation} 2s ease-in 1s infinite;
  visibility: ${props => props.hoverQuote};
  transform: visibility 1s ease-out;

  @media (max-width: 569px) {
    display: none;
  }
`;

const ClickQuote = styled.p`
  display: none;
  text-align: center;
  position: absolute;
  top: 120px;
  right: 50px;
  opacity: 0.3;
  -webkit-animation: ${bounceAnimation} 2s ease-in 1s infinite;
  animation: ${bounceAnimation} 2s ease-in 1s infinite;
  visibility: ${props => props.hoverQuote};
  transform: visibility 1s ease-out;
  line-height: 18px;

  @media (max-width: 569px) {
    display: flex;
  }

  @media (max-width: 375px) {
    right: 14px;
    width: 50%;
  }
`;
const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 18px;
    height: 18px;
    align-self: flex-end;
    margin: 0;
    cursor: pointer;
    fill: ${COLORS.WHITE};
    position: absolute;
    top: 0%;
    right: 0;
    margin: 32px;
  }

`;
const SendInBlueForm = styled.iframe`
  display: block;
  width: 600px;
  height: 750px;
  border: none;
  overflow: none;
  font-family: "Lato", sans-serif !important;
  @media (max-width: 569px){
  width: 100vw;

  }
`

const DownloadButton = styled.button`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.GREEN};
  align-self: center;
  width: fit-content;
  border-radius: 16px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 24px;
  cursor: pointer;
  position: absolute;
  bottom: 80px;

  @media (max-width: 569px){
    position: initial;
    margin-bottom: 64px;  
  }
`

const PostOneDiv = styled.div`
  width: 100%;

  @media (max-width: 569px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 24px;
  }
`;

const PostTwoDiv = styled.div`
  width: 100%;
  @media (max-width: 569px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const PostOne = styled.img`
  max-height: 500px;
  height: 100%;

  @media (max-width: 1024px) {
    max-height: 500px;
  }
  @media (max-width: 569px) {
    max-height: 420px;
    margin: 0;
    align-self: flex-start;
  }
`;

const PostTwo = styled.img`
  max-height: 500px;
  height: 100%;
  position: absolute;
  right: 0;
  @media (max-width: 1024px) {
    max-height: 500px;
  }
  @media (max-width: 569px) {
    position: relative;
    max-height: 420px;
    margin: 0;
    align-self: flex-end;
  }
`;

const SchemeCardOne = styled.div`
  display: none;

  @media (max-width: 569px) {
    display: block;
    width: 180px;
    min-height: fit-content;
    background-color: white;
    box-shadow: 0 0 20px 0 #cccccc;
    align-self: center;
    margin-right: 16px;
  }
`;

const SchemeCardTwo = styled.div`
  display: none;

  @media (max-width: 569px) {
    display: block;
    width: 180px;
    min-height: fit-content;
    background-color: white;
    box-shadow: 0 0 20px 0 #87979c;
    align-self: center;
    margin-left: 24px;
  }
`;

const SchemeCardDiv = styled.div`
  display: ${props => props.displayMode};
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 24px 12px;
`;

const Circle = styled.img`
  transform: ${props => props.hoverSize};
  z-index: 1;
  width: 16px;
  @media (max-width: 569px) {
    width: 20px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Card = styled.div`
  position: absolute;
  padding: 24px 12px;
  width: 180px;
  min-height: fit-content;
  display: ${props => (props.toggleAll ? "block" : props.displayMode)};
  animation: ${fadeIn} 0.5s;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  box-shadow: 0 0 20px 0 #cccccc;
  background-color: white;
  border: 1px solid ${COLORS.GREEN};
  @media (max-width: 569px) {
    display: none;
    position: absolute;
  }
`;

const CardImage = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > img {
    max-height: 120px;
  }
`;

const CardDescription = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 800px;
  }
`;

// const SchemeButton = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   margin-right: 40px;
//   margin-bottom: 32px;
//   width: fit-content;
//   padding: 8px 16px;
//   border-radius: 16px;
//   border: none;
//   background-color: #39e6b5;
//   z-index: 2;
//   font-size: 14px;
//   font-weight: bold;
//   cursor: pointer;
// `;

const PostFeatures = styled.div`
  /* Circles */

  .circle_one {
    position: absolute;
    top: 122px;
    left: 70px;
  }

  .circle_one_one {
    position: absolute;
    top: 100px;
    left: 112px;
  }
  .circle_two {
    position: absolute;
    top: 150px;
    left: 110px;
  }

  .circle_three {
    position: absolute;
    top: 336px;
    left: 110px;
  }
  .circle_four {
    position: absolute;
    top: 526px;
    left: 120px;
  }

  .circle_five {
    position: absolute;
    top: 576px;
    left: 112px;
  }
  .circle_new {
    position: absolute;
    top: 500px;
    left: 105px;
  }

  .circle_six {
    position: absolute;
    top: 105px;
    right: 98px;
  }

  .circle_six_six {
    position: absolute;
    top: 122px;
    right: 66px;
  }

  .circle_seven {
    position: absolute;
    top: 135px;
    right: 98px;
  }
  .circle_eight {
    position: absolute;
    top: 345px;
    right: 98px;
  }
  .circle_nine {
    position: absolute;
    top: 450px;
    right: 98px;
  }
  .circle_ten {
    position: absolute;
    top: 504px;
    right: 78px;
  }
  .circle_eleven {
    position: absolute;
    top: 540px;
    right: 78px;
  }
  .circle_twelve {
    position: absolute;
    top: 565px;
    right: 98px;
  }

  /* Cards */

  .card_one {
    position: absolute;
    top: 28px;
    left: 200px;
  }
  .card_one_one {
    position: absolute;
    top: 44px;
    left: 200px;
  }
  .card_two {
    position: absolute;
    top: 64px;
    left: 200px;
  }

  .card_three {
    position: absolute;
    top: 244px;
    left: 200px;
  }

  .card_four {
    position: absolute;
    top: 370px;
    left: 200px;
  }
  .card_five {
    position: absolute;
    top: 400px;
    left: 200px;
  }
  .card_new {
    position: absolute;
    top: 300px;
    left: 200px;
  }
  .card_six {
    position: absolute;
    top: 20px;
    right: 180px;
  }

  .card_six_six {
    position: absolute;
    top: 30px;
    right: 180px;
  }

  .card_seven {
    position: absolute;
    top: 60px;
    right: 180px;
  }
  .card_eight {
    position: absolute;
    top: 260px;
    right: 180px;
  }
  .card_nine {
    position: absolute;
    top: 340px;
    right: 180px;
  }
  .card_ten {
    position: absolute;
    top: 360px;
    right: 180px;
  }
  .card_eleven {
    position: absolute;
    top: 370px;
    right: 180px;
  }
  .card_twelve {
    position: absolute;
    top: 390px;
    right: 180px;
  }

  @media (max-width: 1024px) {
    .circle_one {
      position: absolute;
      top: 48px;
      left: 68px;
    }
    .circle_one_one {
      position: absolute;
      top: 32px;
      left: 110px;
    }
    .circle_two {
      position: absolute;
      top: 66px;
      left: 110px;
    }

    .circle_three {
      position: absolute;
      top: 236px;
      left: 110px;
    }
    .circle_four {
      position: absolute;
      top: 454px;
      left: 115px;
    }
    .circle_new {
      position: absolute;
      top: 434px;
      left: 105px;
    }
    .circle_five {
      position: absolute;
      top: 499px;
      left: 110px;
    }

    .circle_six {
      position: absolute;
      top: 34px;
      right: 96px;
    }
    .circle_six_six {
      position: absolute;
      top: 50px;
      right: 60px;
    }
    .circle_seven {
      position: absolute;
      top: 56px;
      right: 96px;
    }
    .circle_eight {
      position: absolute;
      top: 260px;
      right: 96px;
    }
    .circle_nine {
      position: absolute;
      top: 365px;
      right: 96px;
    }
    .circle_ten {
      position: absolute;
      top: 430px;
      right: 82px;
    }
    .circle_eleven {
      position: absolute;
      top: 476px;
      right: 84px;
    }
    .circle_twelve {
      position: absolute;
      top: 500px;
      right: 96px;
    }

    /* Cards */

    .card_one {
      position: absolute;
      top: 20px;
      left: 180px;
    }

    .card_one_one {
      position: absolute;
      top: 20px;
      left: 180px;
    }
    .card_two {
      position: absolute;
      top: 40px;
      left: 180px;
    }

    .card_three {
      position: absolute;
      top: 136px;
      left: 180px;
    }

    .card_four {
      position: absolute;
      top: 256px;
      left: 180px;
    }

    .card_new {
      position: absolute;
      top: 226px;
      left: 180px;
    }
    .card_five {
      position: absolute;
      top: 296px;
      left: 180px;
    }
    .card_six {
      position: absolute;
      top: 20px;
      right: 160px;
    }
    .card_six_six {
      position: absolute;
      top: 20px;
      right: 160px;
    }
    .card_seven {
      position: absolute;
      top: 40px;
      right: 160px;
    }
    .card_eight {
      position: absolute;
      top: 140px;
      right: 160px;
    }
    .card_nine {
      position: absolute;
      top: 220px;
      right: 160px;
    }
    .card_ten {
      position: absolute;
      top: 250px;
      right: 160px;
    }
    .card_eleven {
      position: absolute;
      top: 300px;
      right: 160px;
    }
    .card_twelve {
      position: absolute;
      top: 320px;
      right: 160px;
    }
  }
  @media (max-width: 600px) {
    .circle_six {
      position: absolute;
      top: 36px;
      right: 96px;
    }
    .circle_seven {
      position: absolute;
      top: 64px;
      right: 96px;
    }
    .circle_eight {
      position: absolute;
      top: 238px;
      right: 96px;
    }
    .circle_nine {
      position: absolute;
      top: 363px;
      right: 96px;
    }
    .circle_ten {
      position: absolute;
      top: 428px;
      right: 82px;
    }
    .circle_eleven {
      position: absolute;
      top: 474px;
      right: 84px;
    }
    .circle_twelve {
      position: absolute;
      top: 498px;
      right: 96px;
    }

    /* Cards */

    .card_six {
      position: absolute;
      top: 0px;
      right: 160px;
    }
    .card_six_six {
      position: absolute;
      top: 0px;
      right: 160px;
    }
    .card_seven {
      position: absolute;
      top: 10;
      right: 160px;
    }
    .card_eight {
      position: absolute;
      top: 140px;
      right: 160px;
    }
    .card_nine {
      position: absolute;
      top: 240px;
      right: 160px;
    }
    .card_ten {
      position: absolute;
      top: 280px;
      right: 160px;
    }
    .card_eleven {
      position: absolute;
      top: 310px;
      right: 160px;
    }
    .card_twelve {
      position: absolute;
      top: 330px;
      right: 160px;
    }
  }

  @media (max-width: 569px) {
    .circle_one {
      position: absolute;
      top: 45px;
      left: 60px;
    }
    .circle_one_one {
      position: absolute;
      top: 30px;
      left: 96px;
    }
    .circle_two {
      position: absolute;
      top: 60px;
      left: 100px;
    }

    .circle_three {
      position: absolute;
      top: 220px;
      left: 100px;
    }
    .circle_four {
      position: absolute;
      top: 380px;
      left: 108px;
    }

    .circle_new {
      position: absolute;
      top: 362px;
      left: 98px;
    }

    .circle_five {
      position: absolute;
      top: 420px;
      left: 100px;
    }

    .circle_six {
      position: absolute;
      top: 478px;
      right: 88px;
    }
    .circle_six_six {
      position: absolute;
      top: 488px;
      right: 56px;
    }
    .circle_seven {
      position: absolute;
      top: 496px;
      right: 88px;
    }
    .circle_eight {
      position: absolute;
      top: 632px;
      right: 88px;
    }
    .circle_nine {
      position: absolute;
      top: 752px;
      right: 88px;
    }
    .circle_ten {
      position: absolute;
      top: 812px;
      right: 70px;
    }
    .circle_eleven {
      position: absolute;
      top: 842px;
      right: 74px;
    }
    .circle_twelve {
      position: absolute;
      top: 864px;
      right: 86px;
    }
  }
  @media (max-width: 414px) {
    .circle_one {
      position: absolute;
      top: 45px;
      left: 60px;
    }
    .circle_two {
      position: absolute;
      top: 60px;
      left: 98px;
    }

    .circle_three {
      position: absolute;
      top: 220px;
      left: 98px;
    }
    .circle_four {
      position: absolute;
      top: 384px;
      left: 100px;
    }

    .circle_new {
      position: absolute;
      top: 362px;
      left: 88px;
    }

    .circle_five {
      position: absolute;
      top: 420px;
      left: 98px;
    }

    .circle_six {
      position: absolute;
      top: 478px;
      right: 86px;
    }
    .circle_seven {
      position: absolute;
      top: 496px;
      right: 86px;
    }
    .circle_eight {
      position: absolute;
      top: 632px;
      right: 86px;
    }
    .circle_nine {
      position: absolute;
      top: 752px;
      right: 86px;
    }
    .circle_ten {
      position: absolute;
      top: 812px;
      right: 70px;
    }
    .circle_eleven {
      position: absolute;
      top: 842px;
      right: 74px;
    }
    .circle_twelve {
      position: absolute;
      top: 864px;
      right: 86px;
    }
  }
`;

import React from "react";
import styled from "styled-components";
import Image3 from "../assets/images/image3.jpg";
import NewShape from "../assets/forma.svg";
import { COLORS } from "../../style-vars.js";

class Newsletter extends React.Component {
  render() {
    return (
      <div id="newsletter">
        <NewsletterSection>
          <InnerDiv>
            <InnerSection>
              <GreenShape src={NewShape} />
              <RightSection>
                <NewsletterTitle>
                  <h1>
                    <GreenWord>WANT TO</GreenWord> <br />
                    <BlueWord> KNOW MORE</BlueWord>
                    <GreenWord>?</GreenWord>
                  </h1>
                  <p>Get to know smart solutions and learn more about us.</p>
                </NewsletterTitle>
                <SendInBlueForm
                  width="540"
                  height="305"
                  src="https://12de77fb.sibforms.com/serve/MUIEAOn--c6HQ0BOQUhstnQow_lwdgMZ3iGz4Z6tCaW1hdAdvLY7Q0MmbQSEs8z8FRmOHBn-aGQ2UBQG1uF-mzAQH8QOjv0RgV6uJGD8MczkkEueyeNw0l6dJDKxiSJdCrTe9Byu0weht8eyQrufR9gPr-bVXN8zLCpTBgA_yb3Ii1XTC7qsflyuHM4aiX7UwXj3K-rSufOsexPe"
                  frameborder="0"
                  scrolling="no"
                  allowfullscreen
                ></SendInBlueForm>
                {/* <Form>
                  <FormInput
                    type="text"
                    name="email"
                    placeholder="Insert your email here"
                  />
                  <CheckboxDiv>
                    <input type="checkbox" name="privacypolicy" value="agree" />
                    <p>
                      I have read and agree to the{" "}
                      <BoldText>privacy policy.</BoldText>
                    </p>
                  </CheckboxDiv>
                  <FormButton>SUBSCRIBE</FormButton>
                </Form> */}
              </RightSection>
              <LeftSection>
                <NewsletterImage src={Image3} />
                <ContactDiv>
                  <ContactInfo>
                    <p>
                      <BoldText>Contact us</BoldText>
                    </p>
                    <div>
                      <p>
                        <BoldText>Adress</BoldText>
                        <br /> Rua do Poente, Nº 166 - Bougado <br />
                        4785-509, Trofa{" "}
                      </p>
                      <p>
                        <BoldText>Email</BoldText>
                        <br /> info@smartlamppost.com{" "}
                      </p>
                    </div>
                  </ContactInfo>
                </ContactDiv>
              </LeftSection>
            </InnerSection>
          </InnerDiv>
        </NewsletterSection>
      </div>
    );
  }
}
export default Newsletter;

// ------- Overall CSS ------- //

const InnerDiv = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1312px) {
    margin: 0 24px;
  }
`;

const GreenWord = styled.span`
  color: ${COLORS.GREEN};
`;

const BlueWord = styled.span`
  color: ${COLORS.BLUE};
  font-weight: 900;
`;
const BoldText = styled.span`
  font-weight: bold;
`;

// ------- Section nine - Newsletter ------- //
const NewsletterSection = styled.div`
  padding: 160px 0;
  width: 100vw;
  @media (max-width: 600px) {
    padding: 80px 0;
  }
`;

const InnerSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 900px) {
    justify-content: center;
    flex-flow: column wrap;
    height: fit-content;
  }
`;

const RightSection = styled.div`
  width: 60%;
  align-self: center;
  @media (max-width: 900px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
  @media (max-width: 414px) {
    width: 100%;
  }
`;

const GreenShape = styled.img`
  z-index: -1;
  width: 200px;
  position: absolute;
  left: 0;
  margin-top: 200px;
  opacity: 0.4;

  @media (max-width: 1024px) {
    width: 150px;
    margin-top: 200px;
  }
  @media (max-width: 600px) {
    width: 100px;
    margin-top: 200px;
  }
  @media (max-width: 414px) {
    width: 60px;
    margin-top: 240px;
  }
`;

const NewsletterTitle = styled.div`
  margin: auto;
  text-align: center;
  width: 80%;
  & > p {
    margin-bottom: 48px;
  }
  @media (max-width: 600px) {
    & > p {
      margin-bottom: 32px;
    }
  }
  @media (max-width: 414px) {
    & > p {
      margin-bottom: 24px;
    }
  }
`;

// const Form = styled.form`
//   margin: auto;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// const FormInput = styled.input`
//   width: 60%;
//   text-align: center;
//   font-size: 14px;
//   padding: 4px;
//   border-radius: 6px;
//   border: 1px solid #39e6b5;
//   margin-bottom: 16px;
//   @media (max-width: 600px) {
//     width: 80%;
//   }
//   @media (max-width: 414px) {
//     font-size: 12px;
//   }
// `;

// const CheckboxDiv = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 48px;

//   & > p {
//     font-size: 12px;
//     margin: 0;
//     margin-left: 8px;
//   }
//   @media (max-width: 414px) {
//     & > p {
//       font-size: 10px;
//     }
//   }
// `;

// const FormButton = styled.button`
//   height: 51px;
//   width: 149px;
//   border-radius: 16px;
//   border: none;
//   background-color: #39e6b5;
//   font-size: 14px;
//   font-weight: bold;
//   outline: none;
//   -webkit-transition: background-color 0.5s ease-out;
//   -moz-transition: background-color 0.5s ease-out;
//   -o-transition: background-color 0.5s ease-out;
//   transition: background-color 0.5s ease-out;

//   &:hover {
//     background-color: white;
//     border: 2px solid #39e6b5;
//     outline: none;
//     -webkit-transition: background-color 0.5s ease-out;
//     -moz-transition: background-color 0.5s ease-out;
//     -o-transition: background-color 0.5s ease-out;
//     transition: background-color 0.5s ease-out;
//   }

//   &:active {
//     background-color: white;
//     border: 2px solid #39e6b5;
//     transform: translateY(4px);
//     outline: none;
//     -webkit-transition: transition 1s;
//     -moz-transition: transition 1s;
//     -o-transition: transition 1s;
//     transition: transition 1s;
//   }
// `;

const LeftSection = styled.div`
  width: 40%;
  display: flex;
  position: relative;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ContactDiv = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 0;
  align-self: center;
  background-color: ${COLORS.GREEN};
  @media (max-width: 900px) {
    position: relative;
    margin-top: 56px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  font-size: 14px;

  & > div p {
    margin: 0 0 8px 0;
    font-size: 14px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }

  & > p {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const NewsletterImage = styled.img`
  width: 400px;
  @media (max-width: 1024px) {
    width: 300px;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const SendInBlueForm = styled.iframe`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -60px;
  max-width: 100%;
  border: none;
  overflow: none;
  font-family: "Lato", sans-serif !important;
`;

import React from "react";
import styled from "styled-components";
import Blob1 from "../assets/forma2.svg";
import Image1 from "../assets/images/image1.jpg";
import BackgroundTwo from "../assets/backgrounds/header-two.jpg";
import BackgroundTwoMobile from "../assets/backgrounds/header-two-mobile.jpg";
import { COLORS } from "../../style-vars.js";

class Solution extends React.Component {
  render() {
    return (
      <div id="solution">
        <SectionOne>
          <BlobOne src={Blob1} />
          <InnerDiv>
            <TitleOne>
              <h1>
                <BlueWord>STREET LEVEL</BlueWord>{" "}
                <GreenWord>DISTRIBUTED</GreenWord> <br />
                <BlueWord>HOUSING SOLUTIONS</BlueWord> <GreenWord>.</GreenWord>
              </h1>
            </TitleOne>
            <TextOne>
              <p>
                Smartlamppost is a modular concept of urban furniture allowing
                Municipalities, Tower and Infrastructures companies to
                future-proof their offer with a wide range of flexible and
                high-end housing solutions. That will allow to accommodate all
                the needs of a Smart City.
              </p>
            </TextOne>
          </InnerDiv>
        </SectionOne>
        {/* Section Two */}
        <SectionTwo>
          <InnerDiv>
            <Column1>
              <TitleTwo>
                <h1>
                  A SIMPLE <br />
                  <GreenWord>MODULAR</GreenWord> APPROACH
                  <GreenWord>.</GreenWord>
                </h1>
              </TitleTwo>

              <TextTwo>
                <p>
                  Following a simple modular approach, with scalability in mind
                  it offers an easy to mix-and-match modules in elegant and
                  clean solutions.
                </p>
                <p>
                  This will allow all smart cities and street level service
                  providers (telecom, ev-charging, CCTV, Street lighting,…) to
                  share the same infrastructure with cost sharing.
                </p>
              </TextTwo>
            </Column1>
          </InnerDiv>
        </SectionTwo>

        <SectionThree>
          <InnerDiv>
            <BlobTwo src={Blob1} />
            <Column2>
              <ImageOne src={Image1}></ImageOne>
              <TextSection>
                <p>
                  As an agnostic solution, our solution allows integration of
                  almost every service that is being provided at street level,
                  and is ready for future services also.
                </p>
                <p>
                  Monetizing Smartlamppost implies a business model base, in
                  everything, similar to a traditional datacentre housing
                  service, with a time distributed Capex based on tenants needs
                  and existence.
                </p>
              </TextSection>
            </Column2>
          </InnerDiv>
        </SectionThree>
      </div>
    );
  }
}
export default Solution;

// ------- Overall CSS ------- //

const InnerDiv = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1312px) {
    margin: 0 24px;
  }
`;

const GreenWord = styled.span`
  color: ${COLORS.GREEN};
  font-weight: 900;
`;

const BlueWord = styled.span`
  color: ${COLORS.BLUE};
  font-weight: 900;
`;

// ------- Section one - Our Solution ------- //

const SectionOne = styled.div`
  padding-top: 160px;
  padding-bottom: 64px;
  width: 100vw;
  position: relative;

  @media (max-width: 600px) {
    padding-top: 80px;
  }
`;

const TitleOne = styled.div`
  line-height: 64px;
  max-width: 700px;
`;
const TextOne = styled.div`
  width: 50%;

  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const BlobOne = styled.img`
  position: absolute;
  right: 0;
  top: 100px;
  width: 200px;
  z-index: -1;
  opacity: 0.4;

  @media (max-width: 1024px) {
    width: 150px;
  }
  @media (max-width: 900px) {
    width: 100px;
  }
  @media (max-width: 900px) {
    width: 60px;
  }
  @media (max-width: 375px) {
    top: 270px;
  }
`;

// ------- Section two - Our Solution ------- //

const SectionTwo = styled.div`
  padding-top: 160px;
  padding-bottom: 64px;
  width: 100vw;
  height: fit-content;
  position: relative;
  background: url(${BackgroundTwo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  @media (max-width: 600px) {
    padding-bottom: 80px;
    background: url(${BackgroundTwoMobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
`;

const TitleTwo = styled.div`
  line-height: 64px;
  & > h1 {
    color: white;
  }

  @media (max-width: 900px) {
    max-width: 50%;
  }
  @media (max-width: 600px) {
    max-width: 60%;
  }
`;
const TextTwo = styled.div`
  color: white;

  & > p {
    @media (max-width: 600px) {
      line-height: 20px;
    }
  }
`;

const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  & > img {
    margin-bottom: 0;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

// ------- Section three - Our Solution ------- //

const SectionThree = styled.div`
  padding: 160px 0;
  width: 100vw;
  height: fit-content;
  background-color: #f5f5f5;
  @media (max-width: 600px) {
    padding: 80px 0;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 60%;
    margin-left: 24px;
  }

  @media (max-width: 700px) {
    width: 100%;
    margin: 0;
    padding: 0 24px;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 54px;
  justify-content: center;
  @media (max-width: 1200px) {
    padding-left: 0;
    padding-top: 48px;
  }
`;

const ImageOne = styled.img`
  max-width: 500px;
  width: 100%;
  margin: 0;
  z-index: 2;
  @media (max-width: 700px) {
  }
`;

const BlobTwo = styled.img`
  position: absolute;
  right: 0;
  width: 200px;
  opacity: 0.4;
  @media (max-width: 1024px) {
    width: 150px;
  }

  @media (max-width: 500px) {
    width: 50px;
  }
`;
